import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { Swiper } from 'swiper';
import { Helpers } from '../handlers/helpers';
import { SectionType } from "../enums";
import { Button } from '../templates/button';
import { _lang } from '../handlers/langHandler';

const sectionStartDelay = 1;

export default {
    // ID of the section
    sectionId: 6,
    // slug of the section (make sure its unique)
    sectionSlug: "s6",
    // Page title of the loaded section
    sectionTitle: "Awards & Auszeichnungen",
    // Type of the page
    type: SectionType.SLIDE,

    introVideoID: "6_7_pyramide_haende",
    loopVideoID: null,
    loader: '',

    content: /*html*/ `
    <div id="s6-main-container" class="sb-above-pp sb-inner-container">
        <h2 id="s6-title"><span class="sb-entrance-container">${_lang("S6_Title")}</span></h2>
        <p id="s6-content">
        ${_lang("S6_Content_1")}
        </p>
    </div>

    <div id="s6-awards-container">
        <!-- Slider main container -->
        <div class="swiper-container">
        <!-- Additional required wrapper -->
            <div class="swiper-wrapper">
            </div>
        </div>
    </div>
    <a id="s6-next" class="sb-nav-linked sb-next"><lottie-player src="button_screen_07.json"  background="transparent"  style="width: 60px; height: 60px; margin: auto;" speed="1" loop count="4" preserveAspectRatio autoplay></lottie-player></a>
    <div id="s6-wow" class="sb-section-el">WOW</div>
    `,
    show: function(fastSwitch) {
        return new Promise((resolve, reject) => {
            const splitText = new SplitText("#s6-title", { type: "words,chars", charsClass: "sb-split-chars-entrance" });
            const chars = splitText.chars;

            const tl = gsap.timeline({ paused: false, delay: sectionStartDelay });
            tl.from(chars, Helpers.charEntranceAnimDefaults(), "<")
            tl.from("#s6-awards-container", { opacity: 0, duration: .7 });
            tl.from("#s6-wow", { scale: 0, opacity: 0, duration: .7 }, '<');
            tl.from("#s6-content", { opacity: 0 }, "<")
                // Fade in the next button later
            tl.from(".sb-nav-linked, .sb-nav-linked lottie-player", { pointerEvents: "none", opacity: 0, duration: .6 }, ">.6");


            // If fast switch is enabled, skip to the end
            if (fastSwitch) {
                tl.seek('-=0', false);
                resolve();
                return;
            }

            // Once the timeline is completed resolve the promise
            tl.eventCallback("onComplete", resolve)
        });
    },

    dispose: function() {
        if (this.swiper) {
            this.swiper.destroy();
            this.swiper = null;
        }
    },

    onLoad: function() {
        const allAwards = Helpers.getAwardPaths();

        // For every image in the folder generate a slide
        const wrapper = document.querySelector("#s6-awards-container .swiper-container .swiper-wrapper");
        for (const path of allAwards) {
            const el = document.createElement("div");
            el.classList.add("swiper-slide");
            el.innerHTML = /*html*/ `
                <img src=${path}>
           `
            wrapper.appendChild(el);
        }

        this.swiper = new Swiper('#s6-awards-container .swiper-container', {
            direction: 'horizontal',
            noSwiping: true,
            noSwipingSelector: "#s6-awards-container *",
            effect: 'fade',
            slidesPerView: 1,
            fadeEffect: {
                crossFade: true
            },
            speed: 500,            
            loop: true,
            autoplay: {
              delay: 1250,
            }
        });
    },

    hide: () => {
        return Helpers.defaultHide("#s6-main-container, #s6-awards-container, #s6-wow, .sb-nav-linked");
    }
}