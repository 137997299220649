import { Languages } from "../enums";
import { translations } from "../translations/translations";

const defaultLanguage = Languages.DE;

let instance = null;

export class LangHandler {

    // Singleton
    constructor() {
        if (!instance) {
            instance = this;
            this.activeLanguage = defaultLanguage;

            let searchParams;
            // get search parameters
            if ('URLSearchParams' in window) {
                searchParams = new URLSearchParams(window.location.search);
                const lang = searchParams.get("lang");
                this.activeLanguage = Languages[searchParams.get("lang")] || defaultLanguage;
            }
        }
        return instance;
    }

    /**
     * Updates the active language
     * @param {*} newLang the new language
     */
    setActiveLanguage(newLang) {
        this.activeLanguage = newLang;

        let searchParams;
        // get search parameters
        if ('URLSearchParams' in window) {
            searchParams = new URLSearchParams(window.location.search);
        }

        // if the language is the default language remove parameter
        if (newLang == defaultLanguage) {
            searchParams.delete("lang", Languages[newLang]);
            window.location.search = searchParams.toString();

            // otherwise add language and search
        } else {
            searchParams.set("lang", Languages[newLang]);
            window.location.search = searchParams.toString();
        }
    }

    /**
     * Get the active language
     * @returns text,value object of the active language
     */
    getActiveLanguage() {
        // returns the active language
        const lang = this.activeLanguage || defaultLanguage;
        return {
            text: Languages[lang],
            value: lang
        };
    }

    /**
     * Get the inactive language
     * @returns text,value object of the inactive language
     */
    getInactiveLanguage() {
        // return the text and value of the other language
        if (this.activeLanguage == Languages.EN) {
            return {
                text: Languages[Languages.DE],
                value: Languages.DE
            }
        } else {
            return {
                text: Languages[Languages.EN],
                value: Languages.EN
            }
        }
    }
}

/**
 * Get the string by id for the active language
 * @param {*} id id of the string 
 * @returns the translated string for the active language
 */
export function _lang(id = Languages.DE) {
    const langHandler = new LangHandler();
    const activeLang = langHandler.getActiveLanguage().value;
    try {
        return translations[id][activeLang];
    } catch (e) {
        return "";
    }

}