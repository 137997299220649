import gsap from 'gsap';
import { setPage } from '../script.js';
import { NavHandler } from '../handlers/navHandler.js';
import rays from '../../static/page-assets/kontakt/Strahlen-Contact.svg'
import { LangHandler, _lang } from '../handlers/langHandler.js';
import { Languages } from "../enums";

const sectionStartDelay = 0;

function resizeNavBg() {
    const diameter = Math.max(window.innerHeight, window.innerWidth) * 3;
    const bg = document.getElementById("snav-bg");
    bg.style.width = diameter + "px";
    bg.style.height = diameter + "px";
    bg.style.top = -diameter / 2 + "px";
    bg.style.right = -diameter / 2 + "px";
}

export default {
    content: /*html*/ `
    <div id="snav-container" class="sb-inner-container">
        <a id="snav-home-btn" class="sb-menu-item"><h2>Home</h2></a>
        <a id="snav-dbd-btn" class="sb-menu-item"><h2>${_lang("consulting")}</h2></a>
        <a id="snav-about-btn" class="sb-menu-item"><h2>${_lang("about")}</h2></a>
        <a id="snav-methoden-btn" class="sb-menu-item"><h2>Sprints</h2></a>
        <a id="snav-kontakt-btn"  class="sb-menu-item"><h2>${_lang("contact")}</h2></a>
        <a id="snav-lang-btn" class="sb-menu-item"><h2 >${new LangHandler().getInactiveLanguage().text}</h2></a>

        <div id="snav-footer"><a class="sb-menu-item" id="snav-impressum-btn" >${_lang("imprint")} </a> | <a  id="snav-datenschutz-btn" class="sb-menu-item"> ${_lang("privacy-policy")}</a> </div>
    </div>
    <div id="snav-bg"></div>
    <div id="snav-strahlen" class="sb-section-el">${rays}</div>
    <div></div>

    <!--Override menu color-->
    <style>
    #sb-nav-container .sb-menu-btn {
        color: white !important;
    }

    /**Override PP Overlay */
    #sb-pp-overlay {
        z-index: 100;
    }
    
    </style>
    `,
    show: function(fastSwitch) {
        resizeNavBg();

        if (this.entranceTl) {
            this.entranceTl.kill();
            this.entranceTl = null;
        }
        if (this.exitTl) {
            this.exitTl.kill();
            this.exitTl = null;
        }

        this.entranceTl = gsap.timeline({ paused: false, delay: sectionStartDelay });

        this.entranceTl.from("#snav-bg", { scale: 0, duration: 1 });
        this.entranceTl.to("#sb-nav-content", { background: "#dfaa9c" }, "<.6");
        this.entranceTl.from("#snav-strahlen", { scale: 0, duration: .6 }, "<.5");
        this.entranceTl.from(".sb-menu-item, #snav-footer", { stagger: .1, opacity: 0, duration: 1 }, "<");
    },

    hide: function() {
        if (this.entranceTl) {
            this.entranceTl.kill();
            this.entranceTl = null;
        }
        if (this.exitTl) {
            this.exitTl.kill();
            this.exitTl = null;
        }

        return new Promise(function(resolve, reject) {
            resizeNavBg();
            this.exitTl = gsap.timeline({ paused: false, });
            this.exitTl.to("#snav-container", { opacity: 0, duration: .3 });
            this.exitTl.to("#snav-strahlen", { opacity: 0, duration: .6 }, "<");
            this.exitTl.to("#sb-nav-content", { background: "transparent" }, "<");
            this.exitTl.to('#snav-bg', { scale: 0, duration: .6, delay: .3 }, "");
            this.exitTl.eventCallback("onComplete", resolve)
        }.bind(this));
    },


    dispose: function() {
        if (this.entranceTl) {
            this.entranceTl.kill();
            this.entranceTl = null;
        }

        if (this.exitTl) {
            this.exitTl.kill();
            this.exitTl = null;
        }
    },

    onload: function() {

        // Link menu buttons

        // get kontakt button and link it to page switch
        const langBtn = document.querySelector("#snav-lang-btn")
        langBtn.addEventListener("click", function() {
            const langHandler = new LangHandler();
            const inactive = langHandler.getInactiveLanguage().value;
            langHandler.setActiveLanguage(inactive);
        })

        // get kontakt button and link it to page switch
        const kontaktBtn = document.querySelector("#snav-kontakt-btn")
        kontaktBtn.addEventListener("click", function() {
            setPage('kontakt', false);
            NavHandler.toggleMenu();
        })

        // get kontakt button and link it to page switch
        const impressumBtn = document.querySelector("#snav-impressum-btn")
        impressumBtn.addEventListener("click", function() {
            setPage('impressum', false);
            NavHandler.toggleMenu();
        })

        // get kontakt button and link it to page switch
        const datenschutzBtn = document.querySelector("#snav-datenschutz-btn")
        datenschutzBtn.addEventListener("click", function() {
            setPage('datenschutz', false);
            NavHandler.toggleMenu();
        })

        // get methoden button and link it to page switch
        const methodenBtn = document.querySelector("#snav-methoden-btn")
        methodenBtn.addEventListener("click", function() {
            setPage('methoden', false);
            NavHandler.toggleMenu();
        })

        // get home button and link it to page switch
        const homeBtn = document.querySelector("#snav-home-btn")
        homeBtn.addEventListener("click", function() {
            setPage("0", true);
            setTimeout(() => {
                NavHandler.toggleMenu();
            }, 500);
        })

        // get about button and link it to page switch
        const aboutBtn = document.querySelector("#snav-about-btn")
        aboutBtn.addEventListener("click", function() {
            setPage("4", true);
            setTimeout(() => {
                NavHandler.toggleMenu();
            }, 500);
        })

        // get dbd button and link it to page switch
        const dbdBtn = document.querySelector("#snav-dbd-btn")
        dbdBtn.addEventListener("click", function() {
            setPage("1", true);
            setTimeout(() => {
                NavHandler.toggleMenu();
            }, 500);
        })
    }
}