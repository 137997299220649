export const SectionType = {
    "SLIDE": 1,
    "CUSTOM": 2
}
Object.freeze(SectionType);

export const DisplayMode = {
    "DESKTOP": 1,
    "MOBILE": 2
}
Object.freeze(DisplayMode);


export const Languages = {
    "EN": 1,
    "DE": 2,

    1: "EN",
    2: "DE"
}
Object.freeze(Languages);
