import gsap from 'gsap';

let cloudsAnim;
export default {
    template: /*html*/ `<div class="scomponent-cloud"></div>`,

    init: function() {
        if (cloudsAnim) {
            cloudsAnim.kill();
        }
        const cloudEl = document.querySelector(".scomponent-cloud");
        cloudsAnim = gsap.from(cloudEl, { backgroundPosition: "20000%", duration: 4000, ease: "linear", repeat: Infinity });
    },

    dispose: function() {
        if (cloudsAnim) {
            cloudsAnim.kill();
            cloudsAnim = null;
        }
    }
}