import { DisplayMode } from "./enums";

export default {
    "dbd_phoenix": {
        [DisplayMode.DESKTOP]: { name: 'dbd-phoenix-desktop', url: '/videos/desktop/dbd_phoenix_1920x1080.mp4' },
        [DisplayMode.MOBILE]: { name: 'db-phoenix-mobile', url: '/videos/mobile/dbd_phoenix_1080x1920.mp4' }
    },

    "1_2_loader_home": {
        [DisplayMode.DESKTOP]: { name: 'intro' + 0 + '-desktop', url: '/videos/desktop/1-2_loader_home.mp4' },
        [DisplayMode.MOBILE]: { name: 'intro' + 0 + '-mobile', url: '/videos/mobile/1-2_web_video_1080x1920_test.mp4' }
    },

    "2_3_home_digital": {
        [DisplayMode.DESKTOP]: { name: 'intro' + 1 + '-desktop', url: '/videos/desktop/2-3_home_digital.mp4' },
        [DisplayMode.MOBILE]: { name: 'intro' + 1 + '-mobile', url: '/videos/mobile/2-3_web_video_1080x1920_test.mp4' }
    },

    "3_4_digital_pyramide": {
        [DisplayMode.DESKTOP]: { name: 'intro' + 2 + '-desktop', url: '/videos/desktop/3-4_digital_pyramide.mp4' },
        [DisplayMode.MOBILE]: { name: 'intro' + 2 + '-mobile', url: '/videos/mobile/3-4_raute_pyramide.mp4' }
    },

    "4_5_pyramide_phoenix": {
        [DisplayMode.DESKTOP]: { name: 'intro' + 3 + '-desktop', url: '/videos/desktop/4-5_pyramide_phoenix.mp4' },
        [DisplayMode.MOBILE]: { name: 'intro' + 3 + '-mobile', url: '/videos/mobile/4-5_pyramide_sterne.mp4' }
    },

    "5_a_phoenix": {
        [DisplayMode.DESKTOP]: { name: 'intro' + 4 + '-desktop', url: '/videos/desktop/5_a_phoenix.mp4' },
        [DisplayMode.MOBILE]: { name: 'intro' + 4 + '-mobile', url: '/videos/mobile/5-5a_sterne_phoenix.mp4' }
    },

    "5_phoenix_loop": {
        [DisplayMode.DESKTOP]: { name: 'loop' + 4 + '-desktop', url: '/videos/desktop/5_phoenix_loop.mp4' },
        [DisplayMode.MOBILE]: { name: 'loop' + 4 + '-mobile', url: '/videos/mobile/5_phoenix_loop_test.mp4' }
    },
    "5_6_phoenix_pyramide": {
        [DisplayMode.DESKTOP]: { name: 'intro' + 5 + '-desktop', url: '/videos/desktop/5-6_phoenix_pyramide.mp4' },
        [DisplayMode.MOBILE]: { name: 'intro' + 5 + '-mobile', url: '/videos/mobile/5-6_phoenix_transition_test.mp4' }
    },
    "6_7_pyramide_haende": {
        [DisplayMode.DESKTOP]: { name: 'intro' + 6 + '-desktop', url: '/videos/desktop/6-7_pyramide_haende.mp4' },
        [DisplayMode.MOBILE]: { name: 'intro' + 6 + '-mobile', url: '/videos/mobile/6-7_pyramide_transition_test.mp4' }
    },
    "7_8_haende_schrauben": {
        [DisplayMode.DESKTOP]: { name: 'intro' + 7 + '-desktop', url: '/videos/desktop/7-8_haende_schrauben.mp4' },
        [DisplayMode.MOBILE]: { name: 'intro' + 7 + '-mobile', url: '/videos/mobile/7-8_glaskugel_transition_test.mp4' }
    },
    "8_9_schrauben_universum": {
        [DisplayMode.DESKTOP]: { name: 'intro' + 8 + '-desktop', url: '/videos/desktop/8-9_schrauben_universum.mp4' },
        [DisplayMode.MOBILE]: { name: 'intro' + 8 + '-mobile', url: '/videos/mobile/8-9_schrauben_transition_test.mp4' }
    },
    "9_10_universum_auge": {
        [DisplayMode.DESKTOP]: { name: 'intro' + 9 + '-desktop', url: '/videos/desktop/9-10_universum_auge.mp4' },
        [DisplayMode.MOBILE]: { name: 'intro' + 9 + '-mobile', url: '/videos/mobile/9-10_weltall_transition_test.mp4' }
    },
}