import { Languages } from '../enums';
import exampleModule from './modules/exampleModule';
import MainMenuTranslations from './modules/mainMenuTranslations';
import methodsTranslations from './modules/methodsTranslations';
import sectionsTranslations from './modules/sectionsTranslations';

export const translations = {
    // demo top level translation
    "DEMO_TRANSLATION_ID": {
        [Languages.DE]: "Translation in DE..",
        [Languages.EN]: "Translation in EN.."
    },
    ...exampleModule,

    "methods": {
        [Languages.EN]: `Sprints`,
        [Languages.DE]: `Sprints`
    },
    "contact": {
        [Languages.EN]: `Contact`,
        [Languages.DE]: `Kontakt`
    },
    "about": {
        [Languages.EN]: `About`,
        [Languages.DE]: `&Uuml;ber uns`
    },
    "consulting": {
        [Languages.EN]: `Consulting`,
        [Languages.DE]: `Beratung`
    },
    "imprint": {
        [Languages.EN]: `Imprint`,
        [Languages.DE]: `Impressum`
    },
    "privacy-policy": {
        [Languages.EN]: `Privacy Policy`,
        [Languages.DE]: `Datenschutz`
    },

    "SB_Button_Contact": {
        [Languages.DE]: "Kontakt",
        [Languages.EN]: "Contact"
    },

    ...sectionsTranslations,
    ...methodsTranslations,

    // add main menu translations module
    ...MainMenuTranslations
}