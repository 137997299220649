import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { SectionType } from "../enums";
import { Helpers } from '../handlers/helpers';
/*import borderSvg from '../../static/page-assets/section-04/Rahmen-Schlange.svg';
import borderLeftSvg from '../../static/page-assets/section-04/Rahmen-Schlange-side-left.svg';
import borderRightSvg from '../../static/page-assets/section-04/Rahmen-Schlange-side-right.svg';*/
import { Button } from '../templates/button';
import { _lang } from '../handlers/langHandler';

const sectionStartDelay = 1.3;

export default {
    // ID of the section
    sectionId: 4,
    // slug of the section (make sure its unique)
    sectionSlug: "s4",
    // Page title of the loaded section
    sectionTitle: "Wir sind Incredible!",

    // Type of the page
    type: SectionType.SLIDE,

    introVideoID: "5_a_phoenix",
    loopVideoID: "5_phoenix_loop",
    loader: '',

    content: /*html*/ `<div id="s4-main-container" class="sb-above-pp sb-inner-container">
    <h2 id="s4-title"><span class="sb-entrance-container">${_lang("S4_Title")}</span></h2>
    <div id="s4-content">
    <p>${_lang("S4_Content_1")}</p>
    </div>    
</div>
<div style="position:absolute; top:80%"><a id="s4-next" class="sb-nav-linked sb-next"><lottie-player src="button_screen_01.json"  background="transparent"  style="width: 60px; height: 60px; margin: auto;" speed="1" loop count="4" preserveAspectRatio autoplay></lottie-player></a></div>
<div id="s4-experts-since" class="sb-section-el">
    <img id="s4-experts-since-foreground" src="/page-assets/section-04/experts-since.png" sb-mouse-x="6" sb-mouse-y="6"
        class="sb-mouse-tracking sb-tablet-float s4-experts-since">
    <img id="s4-experts-since-background" src="/page-assets/section-04/experts-since-bg.png" sb-mouse-x="12"
        sb-mouse-y="12" class="sb-mouse-tracking s4-experts-since">
</div>

<div id="s4-borders">
    <div id="s4-border-right"></div>
    <div id="s4-border-left"></div>
    <div id="s4-border-top"></div>
    <div id="s4-border-bottom"></div>
</div>`,
    show: (fastSwitch) => {
        return new Promise((resolve, reject) => {

            const splitText = new SplitText("#s4-title", { type: "words,chars", charsClass: "sb-split-chars-entrance" });
            const chars = splitText.chars;

            const tl = gsap.timeline({ paused: false, delay: sectionStartDelay });
            tl.from("#s4-main-container, #s4-borders", { opacity: 0, duration: .7 });

            tl.from(chars, Helpers.charEntranceAnimDefaults(), "<")
            tl.from("#s4-experts-since", { opacity: 0, duration: .6, onComplete: resolve }, "<");
            tl.from("#s4-content", { opacity: 0 })

            // Fade in the next button later
            tl.from(".sb-nav-linked, .sb-nav-linked lottie-player", { pointerEvents: "none", opacity: 0, duration: .6 }, '>.6');


            // If fast switch is enabled, skip to the end
            if (fastSwitch) {
                tl.seek('-=0', false);
                resolve();
                return;
            }

            // Once the timeline is completed resolve the promise
            tl.eventCallback("onComplete", resolve)
        });
    },

    hide: () => {
        return Helpers.defaultHide("#s4-main-container,  #s4-experts-since, #s4-borders");
    }
}