import { state, setPage, sectionMap } from '../script';
import { SectionType } from '../enums';
import sectionNav from '../sections/sectionNav.js';
import SwipeListener from 'swipe-listener';
import { AudioHandler } from './audioHandler';
export class NavHandler {

    /**
     * Link the main navigation menu
     */
    static linkNav() {
        state.navContainer = document.querySelector("#sb-nav-container");
        state.navContent = state.navContainer.querySelector("#sb-nav-content");
        state.navPagination = state.navContainer.querySelector("#sb-nav-pagination");

        const menuFunc = this.toggleMenu.bind(this);

        const menuBtn = state.navContainer.querySelector(".sb-menu-btn");
        menuBtn.addEventListener("click", menuFunc)

        if (state.enablePagination) {
            this._initPagination();
        }
        this._initSwipeEvents();
    }

    /**
     * Toggle the state of the navigation menu
     */
    static toggleMenu() {
        if (!state.isMenuOpen) {
            state.isMenuOpen = true;
            this._openMenu();
        } else {
            state.isMenuOpen = false;
            this._closeMenu();
        }
        new AudioHandler().linkClickSounds();
    }

    static _initSwipeEvents() {
        // Create listener for swipe events
        var listener = SwipeListener(state.contentContainer);

        const onSwipe = (function(e) {
            const pageType = e.currentTarget.getAttribute("type");
            // If we are not on the section page or using touch abort
            if (pageType != SectionType.SLIDE || !e.detail.touch) {
                return;
            }

            var directions = e.detail.directions;

            // Depending on the direction of the swipe go next and previous
            if (directions.left) {
                this.navToDirection(true);
            }

            if (directions.right) {
                this.navToDirection(false, true)
            }
        }).bind(this);

        // On swipe check if we are on the section pages
        state.contentContainer.addEventListener('swipe', onSwipe);
    }

    /**
     * Navigate sections to the next section in said direction
     * @param {*} toRight whether to move right or left
     * @param {*} fastSwitch whether to switch fast
     */
    static navToDirection(toRight = true, fastSwitch = false) {
        const currentId = Number(state.contentContainer.getAttribute("page-id"));
        let nextId = currentId;
        if (toRight) {
            nextId++;
        } else {
            nextId--;
        }
        setPage(nextId, fastSwitch)

    }



    /**
     * Initialize and setup the pagination in the container with bullets
     */
    static _initPagination() {
        // Create the container for the bullets
        const bulletsContainer = document.createElement("div");
        bulletsContainer.classList.add("sb-pagination")

        const slides = [];
        for (const key in sectionMap) {
            if (sectionMap[key].type == SectionType.SLIDE) {
                slides.push(sectionMap[key]);
            }
        }

        // for every page create a bullet navigation item
        for (const slide of slides) {
            const bullet = document.createElement("span");
            // set the item text to the number of the page
            bullet.textContent = slide.sectionId;
            bullet.classList.add("sb-pagination-bullet")
            bullet.setAttribute("page-id", slide.sectionId);

            // add the bullet to the dom
            bulletsContainer.appendChild(bullet);

            bullet.addEventListener("click", () => {
                setPage(slide.sectionId, true);
            })
        }

        state.navPagination.appendChild(bulletsContainer);
    }

    /**
     * 
     * @param {*} pageId id of the page 
     */
    static focusBullet(pageId) {
        const bullets = document.querySelectorAll(".sb-pagination-bullet");
        for (const bullet of bullets) {
            bullet.classList.remove("sb-active");
        }

        const target = document.querySelector(`.sb-pagination-bullet[page-id="${pageId}"]`);

        // If the page exists in the pagination bullets make it visible and active
        // otherwise hide the pagination
        if (target) {
            state.navPagination.style.visibility = "visible";
            target.classList.add("sb-active");
        } else {
            state.navPagination.style.visibility = "hidden";
        }
    }

    /**
     * Open the main nav menu
     */
    static _openMenu() {
        const icon = state.navContainer.querySelector("#sb-nav-icon");
        icon.classList.add("sb-nav-open");


        // Make the nav content visible
        state.navContent.style.visibility = "visible";

        // load the nav section
        state.navContent.innerHTML = sectionNav.content;

        // call load function
        sectionNav.onload();



        // animate in
        sectionNav.show();

    }

    /**
     * Closes the main menu nav
     */
    static async _closeMenu() {
        const icon = state.navContainer.querySelector("#sb-nav-icon");
        icon.classList.remove("sb-nav-open");

        await sectionNav.hide();
        sectionNav.dispose();

        // if the menu is closing / closed hide it
        if (!state.isMenuOpen) {

            // load the nav section
            state.navContent.innerHTML = "";
            state.navContent.style.visibility = "hidden";
        }

    }
}