import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { SectionType } from '../enums';
import { Helpers } from '../handlers/helpers';
import { setPage } from '../script';
import sectionMethoden from './sectionMethoden';
import { Button } from '../templates/button';
import { _lang } from '../handlers/langHandler';
const sectionStartDelay = 1.5;

export default {
    // ID of the section
    sectionId: 3,
    // slug of the section (make sure its unique)
    sectionSlug: "s3",
    // Page title of the loaded section
    sectionTitle: "Aus der Praxis für die Praxis.",
    // Type of the page
    type: SectionType.SLIDE,

    introVideoID: "4_5_pyramide_phoenix",
    loopVideoID: null,

    loader: '',

    content: /*html*/ `
    <div id="s3-main-container" class="sb-above-pp sb-inner-container">

        <h2 id="s3-title"><span class="sb-entrance-container">${_lang("S3_Title")}</span></h2>
        <div id="s3-content">
          <p>${_lang("S3_Content_1")}</p>
          <p>${_lang("S3_Content_2")}</p>
        </div>

        <div id="s3-nav-container"> 
            <a id="s3-next" class="sb-nav-linked sb-next"><lottie-player src="button_screen_05.json"  background="transparent"  style="width: 60px; height: 60px; margin: auto;" speed="1" loop count="4" preserveAspectRatio autoplay></lottie-player></a>
        </div>
    </div>
    <div id="s3-symbol1" class="sb-float sb-section-el s3-symbol" > 
        <div class="s3-symbol-wrapper">
        <img src="/page-assets/section-03/symbol-links.png">
        <img src="/page-assets/section-03/symbol-links.png">
        </div>
    </div>
    <div id="s3-symbol2" class="sb-float sb-section-el s3-symbol" > 
        <div class="s3-symbol-wrapper" >
            <img src="/page-assets/section-03/symbol-rechts.png">
            <img src="/page-assets/section-03/symbol-rechts.png">
        </div>
    </div>
    `,
    show: (fastSwitch) => {

        return new Promise((resolve, reject) => {

            const splitText = new SplitText("#s3-title", { type: "words,chars", charsClass: "sb-split-chars-entrance" });
            const chars = splitText.chars;


            const tl = gsap.timeline({ paused: false, delay: sectionStartDelay });
            tl.from("#s3-symbol1", { opacity: 0, duration: .6 });
            tl.from("#s3-symbol2", { opacity: 0, duration: .6, onComplete: resolve }, "<");
            tl.from(chars, Helpers.charEntranceAnimDefaults());
            tl.from("#s3-content", { opacity: 0 });

            // Fade in the next button later
            tl.from(".sb-nav-linked, .sb-nav-linked lottie-player", { pointerEvents: "none", opacity: 0, duration: .6 });

            // If fast switch is enabled, skip to the end
            if (fastSwitch) {
                tl.seek('-=0', false);
                resolve();
                return;
            }

            // Once the timeline is completed resolve the promise
            tl.eventCallback("onComplete", resolve)
        });
    },

    hide: () => {
        return Helpers.defaultHide("#s3-main-container, .s3-symbol");
    }
}