import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { Power4 } from 'gsap';
import Swiper from 'swiper/bundle';
import { Helpers } from '../handlers/helpers';
import { SectionType } from "../enums";
import { Button } from '../templates/button';
import kranzLinks from '../../static/page-assets/section-05/Lorbeerkranz-Links.svg';
import kranzRechts from '../../static/page-assets/section-05/Lorbeerkranz-Rechts.svg';
import movingClouds from '../templates/movingClouds';
import { _lang } from '../handlers/langHandler';

const sectionStartDelay = 1.3;

export default {
    // ID of the section
    sectionId: 5,
    // slug of the section (make sure its unique)
    sectionSlug: "s5",
    // Page title of the loaded section
    sectionTitle: "Exzellenz seit 1999.",
    // Type of the page
    type: SectionType.SLIDE,

    introVideoID: "5_6_phoenix_pyramide",
    loopVideoID: null,
    loader: '',


    content: /*html*/ `
    
   ${movingClouds.template} 
    <div class=" sb-above-pp sb-inner-container">
    <div id="s5-main-container">
        <h2 id="s5-title"><span class="sb-entrance-container">${_lang("S5_Title")}</span></h2>
        <p id="s5-content">
            ${_lang("S5_Content_1")}        
        </p>
    </div>
    <div id="s5-awards-container">
        <div class="s5-kranz">
            ${kranzLinks}
        </div>
        <!-- Slider main container -->
        <div class="swiper-container">
            <!-- Additional required wrapper -->
            <div class="swiper-wrapper">
                <!-- Slides -->
            </div>
        </div>
        <div class="s5-kranz">
            ${kranzRechts}
        </div>
    </div>
    <a id="s5-next" class="sb-nav-linked sb-next"><lottie-player src="button_screen_06.json"  background="transparent"  style="width: 60px; height: 60px; margin: auto;" speed="1" loop count="4" preserveAspectRatio autoplay></lottie-player></a>
</div>
<img id="s5-moon" class="sb-section-el" src="/page-assets/section-05/Moon.png">
<img id="s5-phyramide" sb-mouse-x="-15" sb-mouse-y="-15" class="sb-mouse-tracking sb-section-el"
    src="/page-assets/section-05/Phyramide.png">`,
    show: (fastSwitch) => {
        return new Promise((resolve, reject) => {
            const splitText = new SplitText("#s5-title", { type: "words,chars", charsClass: "sb-split-chars-entrance" });
            const chars = splitText.chars;


            const tl = gsap.timeline({ paused: false, delay: sectionStartDelay });
            tl.from(".scomponent-cloud", { opacity: 0, duration: .7 });
            tl.from(chars, Helpers.charEntranceAnimDefaults(), "<")
            tl.from("#s5-content", { opacity: 0 })
            tl.from("#s5-awards-container", { opacity: 0, duration: .6 }, '<0.6');

            // Fade in the next button later
            tl.from(".sb-nav-linked, .sb-nav-linked lottie-player", { pointerEvents: "none", opacity: 0, duration: .6 }, ">.6");

            //background timeline
            const bgtl = gsap.timeline({ paused: false });
            // Animate bg elements
            bgtl.from("#s5-phyramide", { ease: Power4.easeOut, filter: "blur(200px)", x: '-250%', y: '40%', delay: 1, scale: 1.6, duration: 1.5, onComplete: resolve })
            bgtl.from("#s5-moon", { opacity: 0, duration: .1 });
            bgtl.from("#s5-moon", { y: 200, delay: .5, duration: 1.5 }, '<');


            // If fast switch is enabled, skip to the end
            if (fastSwitch) {
                tl.seek('-=0', false);
                bgtl.seek("-=0", false);
                resolve();
                return;
            }

            // Once the timeline is completed resolve the promise
            tl.eventCallback("onComplete", resolve)
        });
    },

    dispose: function() {
        if (this.swiper) {
            this.swiper.destroy();
            this.swiper = null;
        }

        movingClouds.dispose();
    },

    onLoad: function() {
        movingClouds.init();

        const allAwards = Helpers.getLogoPaths();

        // For every image in the folder generate a slide
        const wrapper = document.querySelector("#s5-awards-container .swiper-container .swiper-wrapper");
        for (const path of allAwards) {
            const el = document.createElement("div");
            el.classList.add("swiper-slide");
            el.innerHTML = /*html*/ `
                <img src=${path}>
           `
            wrapper.appendChild(el);
        }

        let slidesPerView = 5;
        let overrideProperties = {};
        if (window.innerWidth < 1024 && window.innerWidth > 768) {
            overrideProperties.slidesPerView = 3;
        } else if (window.innerWidth < 768) {
            overrideProperties.slidesPerView = 1;
            overrideProperties.effect = "fade";
            overrideProperties.fadeEffect = {
                crossFade: true
            }
            overrideProperties.speed = 1000;
            overrideProperties.autoplay = {
                enabled: true,
                disableOnInteraction: false,
                delay: 2000,
            }
        }

        this.swiper = new Swiper('#s5-awards-container .swiper-container', {
            direction: 'horizontal',
            spaceBetween: 15,
            noSwiping: true,
            noSwipingSelector: "#s5-awards-container *",
            delay: 0,
            loop: true,
            freeMode: true,
            slidesPerView: slidesPerView,
            speed: 2000,
            autoplay: {
                enabled: true,
                disableOnInteraction: false,
                delay: 0,
            },
            ...overrideProperties
        });
    },

    hide: () => {
        return Helpers.defaultHide("#s5-main-container, #s5-awards-container, #s5-moon, #s5-phyramide, .sb-nav-linked");
    }
}