import { Languages } from "../../enums"

export default {

    "Methods_Digital_Business_Design_Sprints": {
        [Languages.DE]: `
        <p>Bei einem Sprint arbeitet unser Team aus Strategen, Designern, Marketing-Experten und Entwicklern über einen Zeitraum von ca. 2-3 Wochen intensiv an deinem Projekt.</p>
        <p>Wir bieten 6 Arten von Sprints, die aufeinander aufbauen und je nach Aufgabenstellung kombiniert werden.</p>
        <ol>
        <li>Strategy Sprint: Identifiziert die Zielsetzung und gibt die Richtung vor.
        <li>Creative Exploration: Findet innovative, neue Lösungsansätze.
        <li>Design Quest: Gibt deinem Projekt eine einzigartige Gestalt.
        <li>Reality Check: Zeigt, was im Markt funktioniert.
        <li>Development Sprint: Sorgt für eine perfekte Umsetzung.
        <li>Training & Coaching: Gibt deinem Team alles, was es braucht, um alleine klarzukommen.
        </ol>
        <p>Am Ende jedes Sprints steht immer ein konkretes, handfestes Ergebnis, mit dem du sofort auch unabhängig von uns weiterarbeiten kannst. Wir begleiten dich genau so lange, wie es für dich und das Projekt sinnvoll ist.</p>`,
        [Languages.EN]: `
        <p>During a sprint, our team of strategists, designers, marketing experts and developers work intensively on your project over a period of approx. 2-3 weeks.</p>
        <p>We offer 6 different sprints, which build on each other and are combined depending on the task at hand.</p>
        <ol>
        <li>Strategy Sprint: Identifies the objective and sets the course.</li>
        <li>Creative Exploration: Finds innovative, new solutions.</li>
        <li>Design Quest: Provides your project with its unique shape.</li>
        <li>Reality Check: Highlights what’s working in the market.</li>
        <li>Development Sprint: Ensures the project is implemented flawlessly.</li>
        <li>Training & Coaching: Provides your team with everything it needs to go it alone.</li>
        </ol>
        <p>At the end of every sprint, there’s always a palpable, tangible result, with which you can immediately continue working without us. We’ll accompany you for as long as it makes sense for you and the project.</p>
        `,

    },

    "Methods_Strategy_Sprint": {
        [Languages.DE]: `<p>Bevor man sich auf den Weg macht, sollte man wissen, wo man hin will.</p>
        <p>Gemeinsam finden wir heraus, wo der Schuh wirklich drückt und wo ungenutzte Potenziale schlummern. Daraus entwickeln wir handfeste, digital befeuerte Business-Ideen, die im Markt funktionieren – und nicht nur auf Post Its und Powerpoint-Folien.</p>
        <p>Am Ende des Strategie-Sprints steht eine gestärkte Identität der Marke, eine digitale Positionierung, ein neues Marktsegment mit Potential, ein vielversprechendes Business Modell für die Zukunft oder eine Kernbotschaft & Story, die den Mehrwert auf den Punkt bringt. </p>
        <p>Der Strategy Sprint ist die Basis für unsere gemeinsame Arbeit.</p>
        <p>Du kannst die Ergebnisse aber auch als detaillierte Briefingrundlage für jeden Umsetzungspartner deiner Wahl verwenden.</p>`,
        [Languages.EN]: `<p>Before you set off, you should decide where you are going.</p>
        <p>We’ll work together to find out what you really need and where untapped potential lies dormant. From this, we’ll develop tangible, digitally-fueled business ideas that work on the market – and not just on Post-Its and PowerPoint slides.</p>
        <p>At the end of the Strategy Sprint, you’ll have a refreshed brand identity, digital positioning, a new market segment with potential, a promising business model for the future or a core message & story that sums up the added value.</p>
        <p>The Strategy Sprint is our basis as we move forward together.</p>
        <p>You can also take these results as a detailed briefing and pick any partner you like to help you implement them.</p>
        `
    },

    "Methods_Creative_Exploration": {
        [Languages.DE]: `<p>Außergewöhnliche Ideen entstehen nicht aus dem Nichts.</p>
        <p>Mit erprobten Methoden schaffen wir die Grundlagen für echte Innovationen.</p>
        <p>Gemeinsam mit Experten sorgen wir dafür, dass du und dein Team einen neuen Blick von außen auf eingefahrene Prozesse werfen können und brechen mit alten Denkmustern. Wir starten mit einem Inspirationsboost von Trends und Best Practices aus der ganzen Welt und kombinieren die besten Kreativ- und Innovationsmethoden, um frische Lösungsansätze zu entdecken.</p>
        <p>So bekommst du ganz neue Perspektiven auf dein Produkt und den Markt und entdeckst ungeahnte Möglichkeiten, die neue Energie in dein Unternehmen bringen.</p>                                      
        </p>`,
        [Languages.EN]: `<p>Extraordinary ideas aren’t plucked out of thin air.</p>
        <p>We’ll use our tried-and-tested methods to create the basis for real innovations.</p>
        <p>Working with experts, we’ll ensure that you and your team can take a fresh, critical look at well-established processes, breaking with old patterns of thought. We’ll start with an inspiration boost provided by trends and best practices from all over the world and combine the best creative and innovation methods to discover fresh solutions.</p>
        <p>This means you’ll get completely new perspectives about your product and the market, discovering hitherto unimagined possibilities that breathe new energy into your company.</p>
        `
    },
    "Methods_Design_Quest": {
        [Languages.DE]: `  <p>In unserem Design Sprint bekommt dein Projekt einen konkreten Look & Feel.</p>
        <p>Preisgekrönte Designer arbeiten für 3 Wochen gemeinsam mit Strategen und UX Experten intensiv an deiner Aufgabenstellung. Entwerfen & verwerfen, entwickeln Lösungen & Prototypen. So lange, bis das Erscheinungsbild deines Projekts genau am Punkt ist. Unser Ziel ist es, kreative Exzellenz mit echter Wertschöpfung zu verbinden. </p>
        <p>Wir finden eine Form, die deine Botschaft zielgruppengenau vermittelt. Design, das heraussticht und performt. Wir entwickeln für dich ein einzigartiges digitales und analoges Auftreten und machen die Nutzung deines Produkts zu einem Erlebnis.</p>
        </p>`,
        [Languages.EN]: `<p>In our Design Sprint, your project is given a tangible look and feel.</p>
        <p>Award-winning designers will work intensively on your assignment for 3 weeks together with strategists and UX experts. Designing & discarding, developing solutions & prototypes – until the look of your project is exactly on point. Our goal is to combine creative excellence with real added value. We’ll find a form that conveys your message to the target group. Design that stands out and performs.</p>
        <p>We’ll develop a unique digital and analog appearance for you, transforming the use of your product into an experience.</p>
        `
    },
    "Methods_Reality_Check": {
        [Languages.DE]: `<p>Build the right it instead of building it right.</p>
        <p>Man kann sehr lange an einem Projekt arbeiten, aber nicht jede gute Idee überzeugt am Markt.</p>
        <p>Schlimmstenfalls kommt der Moment der Wahrheit erst beim Launch. Dann, wenn geliebte und im Detail ausgetüftelte Lösungen auf reale Nutzer und Bedürfnisse treffen. Und sich vielleicht als völlig falsch herausstellen.</p>
        <p>Mit einem frühen Reality Check testen wir ein Projekt, bevor es an die Umsetzung geht. Dafür nutzen wir moderne Pretotyping-Methoden und reale Nutzungssimulationen, statt aufwendiger Marktforschung. Fake it before you make it. Das spart wirklich Zeit und Geld.</p>`,
        [Languages.EN]: `<p>Build the right it instead of building it right.</p>
        <p>You can spend a great deal of time on a project, but not every good idea wows the market. </p>
        <p>In the worst case scenario, the moment of truth only comes during the launch – where beloved and carefully worked out solutions meet real users and needs. And may turn out to be completely wrong. </p>
        <p>We test projects with an early Reality Check before implementing it. We use modern pretotyping methods and real usage simulations instead of laborious market research. Fake it til you make it. It really saves time and money.</p>
        `
    },

    "Methods_Development_Sprint": {
        [Languages.DE]: `  <p>
        Wir lassen dich nicht mit guten Konzepten allein, sondern begleiten dich auf Wunsch bis zu der fertigen Umsetzung und darüber hinaus. Entweder wir legen selbst Hand an - oder wir vernetzen dich mit den richtigen Partnern für deine Aufgabenstellung. Als Mitgründer der Tomorrow Academy haben wir für jede Anforderung die passenden Experten in unserem Netzwerk. Wir haben selbst über 1000 digitale Entwicklungsprojekte erfolgreich inhouse umgesetzt und wissen, welcher Weg mit welchen Anbietern am effizientesten zum Ziel führt. Wenn du möchtest übernehmen wir die Regie und Qualitätskontrolle und sorgen dafür, dass dein Projekt bis zum letzten Feinschliff nach Plan funktioniert.
        </p>`,
        [Languages.EN]: `<p>We don’t leave you with good concepts alone. If you want us to, we’ll support you until completion and even beyond. We’ll either lend a hand ourselves – or we connect you to the right partners for your project. As co-founders of the Tomorrow Academy, we have the right experts in our network to suit any requirement. We have successfully implemented over 1000 digital development projects in-house and know which routes and which providers will most efficiently achieve your goal. If you want, we can manage the project and perform the quality checks, ensuring your project goes according to plan right down to the finishing touches.</p>`
    },

    "Methods_Coaching_Training_Advice": {
        [Languages.DE]: ` <p>Unser Ziel ist immer, dass dein Projekt ohne uns funktioniert. Deshalb trainieren wir auf Wunsch dein Team in allen notwendigen Skills, um alleine erfolgreich zu sein oder vernetzen dich mit Freelancern und passenden Mitarbeitern. Alles, damit dein Unternehmen langfristig autonom und unabhängig erfolgreich ist.</p>
        <p>Wir stehen dir mit unserer geballten Erfahrung gerne auch nur punktuell als Sparring-Partner und Feedbackgeber zur Verfügung. Manchmal braucht es einen kurzen, erfahren Blick von außen, um ein Projekt wieder auf Schiene zu bekommen. Wir kombinieren kreative Sichtweisen mit Business Know-How und sprechen die Sprache von Programmierern und Unternehmern. Wir sind ausgebildete Coaches und Organisationsentwickler und bringen 20 Jahre digitale Expertise an den Tisch. Und das gerne auch nur für wenige Stunden. Unser Senf ist manchmal Gold wert.</p>
   `,
        [Languages.EN]: `<p>Our aim is to ensure that your project works without us. On request, we can train your team in all the skills required for you to be successful on your own or we can connect you with freelancers and suitable employees. We’ll take all the steps to ensure your company can be autonomous and independently successful in the long term.</p>
        <p>Using our cumulative experience, we’re also happy to be your sparring partner and provide feedback only as and when you need us. Sometimes it takes a brief, experienced look from the outside to get a project back on track. We combine creative perspectives with business know-how and speak the language of programmers and entrepreneurs. We’re trained coaches and organizational developers, with 20 years of digital expertise that you might only need for a few hours. Our two cents might be worth a mint to you.</p>`
    },
    
    "Methods_Coaching_Training_Advice_HL": {
        [Languages.DE]: `Coaching, Training & Beratung`,
        [Languages.EN]: `Coaching, Training & Consulting`
    }
    
}