import gsap from 'gsap';
import button from '../../static/page-assets/components/button.svg';
import borderSvg from '../../static/page-assets/section-04/Rahmen-Schlange.svg';
import borderLeftSvg from '../../static/page-assets/section-04/Rahmen-Schlange-side-left.svg';
import borderRightSvg from '../../static/page-assets/section-04/Rahmen-Schlange-side-right.svg';

import { SplitText } from 'gsap/SplitText';
import { Languages, SectionType } from "../enums";
import { Helpers } from '../handlers/helpers';
import { Button } from '../templates/button';

import { _lang } from '../handlers/langHandler';

const sectionStartDelay = 1.3;

export default {
    // ID of the section
    sectionId: 0,
    // slug of the section (make sure its unique)
    sectionSlug: "s0",
    // Page title of the loaded section
    sectionTitle: "Business-Konzepte für die digitale Welt.",
    // Type of the page
    type: SectionType.SLIDE,

    // paths to the intro video
    introVideoID: "1_2_loader_home",
    // Paths to the loop video
    loopVideoID: null,
    texture: '',
    sprite: false,
    loader: '',



    content: /*html*/ `
        <div id="s0-main-container" class="sb-inner-container sb-above-pp">

        <h2 id="s0-title"><span class="sb-entrance-container">screenagers & the incredible machine</span></h2>
        <div id="s0-content">
        <p>${_lang("S0_Welcome_2")}</p>
        </div>
        
        <a id="s0-next" class="sb-nav-linked sb-next">${new Button('START').content}</a>
    </div>
    
    <div id="fwa-logo-desktop" style="position:absolute; top:0; left:0;"><img src="/page-assets/section-00/FOTD-top-left_IM_special.png" alt="FWA Logo" width="75"></div>
	     <div id="fwa-logo-mobile" style="position:absolute; bottom:-10px; left:0;"><img src="/page-assets/section-00/FOTD-bottom-left_IM_special.png" alt="FWA Logo" width="80"></div>


    <div id="s0-borders">
      <div id="s0-border-right"></div>
      <div id="s0-border-left"></div>
      <div id="s0-border-top"></div>
      <div id="s0-border-bottom"></div>
    </div>    
    `,
    show: (fastSwitch) => {
        return new Promise((resolve, reject) => {

            const tl = gsap.timeline({ paused: false, delay: sectionStartDelay });
            tl.from(".sb-entrance-container", { y: 100 + "%" }, "<")
            tl.from("#s0-main-container, #s0-borders, #fwa-logo-desktop, #fwa-logo-mobile", { opacity: 0, duration: .7 });
            tl.from("#s0-content", { opacity: 0, duration: .6 }, "<")

            // Fade in the next button later
            tl.from(".sb-nav-linked, .sb-nav-linked lottie-player", { pointerEvents: "none", opacity: 0, duration: .6 });

            // If fast switch is enabled, skip to the end
            if (fastSwitch) {
                tl.seek('-=0', false);
                resolve();
                return;
            }


            // Once the timeline is completed resolve the promise
            tl.eventCallback("onComplete", resolve)
        });
    },

    hide: () => {
	    Helpers.defaultHide("#fwa-logo-desktop");
	    Helpers.defaultHide("#fwa-logo-mobile");
        return Helpers.defaultHide("#s0-main-container");
    }
}