let instance = null;
import { sectionMap, slugIdMap, setPage, state, generateVideoTextures } from '../script';
import { Helpers } from './helpers';

let cooldownTimeout;


// Singleton class to handle the history
export class HistoryHandler {

    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    init() {
        // Get current page id
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get("page");

        // If the page exists go to it
        if (Object.keys(slugIdMap).includes(id) && slugIdMap[id] !== state.landingPageId) {
            Helpers.startLoading();
            generateVideoTextures().then(
                () => {
                    setPage(slugIdMap[id], true);
                }
            )
        } else {
            setPage(state.landingPageId, false)
            generateVideoTextures().then(sectionMap[state.landingPageId].startPageTransition);
        }

        window.addEventListener("popstate", (e) => {
            clearTimeout(cooldownTimeout);

            cooldownTimeout = setTimeout(() => {
                const type = sectionMap[e.state.sectionId].type;
                // Depending on the type from the popped state navigate or set to the page
                // with the id of the previous staet
                setPage(e.state.sectionId, true, false)
            }, 300);


        });
    }

    /**
     * Pushes new page onto the history stack
     * @param {*} id id of the page 
     * @param {*} replace whether to replace or push additionally
     */
    pushNewPage(id, replace = false) {
        const page = sectionMap[id];
        let nextURL = "/"

        // try to add the new page id to the search parameters
        if ('URLSearchParams' in window && page.sectionSlug) {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set("page", page.sectionSlug);

            // Update the next url with the search parameters
            nextURL = nextURL + "?" + searchParams.toString();
        }


        // update the next title and state
        const nextTitle = page.sectionTitle;
        const nextState = { sectionId: page.sectionId };

        if (replace) {
            // This will replace the current entry in the browser's history, without reloading
            window.history.replaceState(nextState, nextTitle, nextURL);
        } else {
            // Create new entry in the history states
            window.history.pushState(nextState, nextTitle, nextURL);
        }
    }

}