import * as PIXI from 'pixi.js';
import logoTopHalf from '../../static/page-assets/home/logo-top-half.svg';
import imLogo from '../../static/page-assets/home/im-logo-bottom-half.svg';

const speed = 1;

let animationFrame;
let stage;
let renderer;
let slidesContainer;
let displacementSprite;
let displacementFilter;

export default {
    template: /*html*/ `<div class="sb-above-pp" id="scomponent-im-text">${logoTopHalf}</div>`,

    init: function(isBlack = false) {
        PIXI.utils.skipHello();
        const container = document.querySelector("#scomponent-im-text");

        const displacementImage = '/page-assets/home/displacement-map.jpeg';
        renderer = new PIXI.Renderer({ backgroundAlpha: 0, width: 400, height: 400 });
        stage = new PIXI.Container();
        slidesContainer = new PIXI.Container();
        displacementSprite = new PIXI.Sprite.from(displacementImage);
        displacementFilter = new PIXI.filters.DisplacementFilter(displacementSprite);

        // Add canvas to the HTML
        container.appendChild(renderer.view);

        // Add child container to the stage
        stage.addChild(slidesContainer);

        displacementSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;
        displacementSprite.anchor.set(0.5);

        displacementSprite.scale.x = 0.7;
        displacementSprite.scale.y = 0.7;


        displacementSprite.x = renderer.width / 2;
        displacementSprite.y = renderer.height / 2;
        stage.addChild(displacementSprite);


        // Set the filter to stage
        stage.filters = [displacementFilter];

        // We load the sprites to the slides container and position them at the center of the stage
        // The sprites array is passed to our component upon its initialization
        // If our slide has text, we add it as a child to the image and center it
        let texture = new PIXI.Texture.from((!isBlack) ? "/page-assets/home/im-logo-yellow.png" : "/page-assets/home/im-logo.png");
        let image = new PIXI.Sprite(texture);

        image.anchor.set(0.5);


        image.x = renderer.width / 2;
        image.y = renderer.height / 2;

        slidesContainer.addChild(image);

        animate();

        function animate() {
            // Optionally have a default animation
            displacementSprite.x += speed;
            displacementSprite.y += speed;


            // Render our stage
            renderer.render(stage);
            animationFrame = requestAnimationFrame(animate);
        };
    },

    dispose: () => {
        // Cancel the anim loop
        cancelAnimationFrame(animationFrame);

        // destroy the stage /renderer
        try {
            displacementFilter.destroy();
            displacementSprite.destroy();
            slidesContainer.destroy();
            stage.destroy({ children: true, texture: true, baseTexture: true });
            renderer.destroy();
        } catch (e) {}
    }
}