import gsap from 'gsap';
import { Linear } from 'gsap/all';
import { SectionType } from "../enums";
import rays from '../../static/page-assets/kontakt/Strahlen-Contact.svg'
import incredibleLogo from '../templates/incredibleLogo';
import { _lang } from '../handlers/langHandler';

const sectionStartDelay = 1.6;

export default {
    // ID of the section
    sectionId: "kontakt",
    // slug of the section (make sure its unique)
    sectionSlug: "kontakt",
    // Page title of the loaded section
    sectionTitle: "kontakt",
    // Type of the page
    type: SectionType.CUSTOM,

    content: /*html*/ `
    <div id="skontakt-main-container" class="sb-above-pp sb-inner-container">
        ${incredibleLogo.template}
        <p id="skontakt-content">
        ${_lang("Contact_Content_1")}
        </p>
        <ul>
            <li>Mittelgasse 4/13</li>
            <li>1060 Vienna, Austria</li>
            <li>T <a href="tel:+4319971644">+43 (0) 1 997 1644</a></li>
            <li><a href="mailto:office@screenagers.com">office@screenagers.com</a></li>
        </ul>
        </p>
    </div>
    <span id="skontakt-majong1" class="sb-section-el"><img class="sb-mouse-tracking" sb-mouse-x="21" sb-mouse-y="21" src="/page-assets/kontakt/Majong-01.svg"></span>
    <span id="skontakt-majong2" class="sb-section-el" ><img class="sb-mouse-tracking"  sb-mouse-x="-15"  sb-mouse-y="-15" src="/page-assets/kontakt/Majong-02.svg"></span>
    <span id="skontakt-strahlen" class="sb-section-el">${rays}</span>
    <!--Override menu color and page colour-->
    <style>
    .sb-menu-btn *{
        stroke: black !important;
    }
    </style>
    `,
    onLoad: function() {
        incredibleLogo.init(true);
    },

    dispose: () => {
        incredibleLogo.dispose();
    },

    show: (fastSwitch) => {
        return new Promise((resolve, reject) => {
            resolve();
            return;
            const tl = gsap.timeline({ paused: false, delay: sectionStartDelay });
            tl.from("#scomponent-im-text", { y: -200, opacity: 0, duration: 1 });
            tl.from("#skontakt-majong1, #skontakt-majong2", { opacity: 0, duration: .6 }, "<")
            tl.from("#skontakt-strahlen", { scale: 0, duration: .6 }, "<");
            tl.from("#skontakt-content", { opacity: 0, duration: 1 }, '<');
            tl.from(".sb-bg-img", { opacity: 0, y: -100, duration: 2 }, '<');
            tl.from("#skontakt-main-container ul li", { opacity: 0, stagger: .2, duration: 2 }, '<');

            gsap.to("#skontakt-majong1", { repeat: Infinity, rotation: 360, ease: Linear.easeNone, duration: 20, });
            gsap.to("#skontakt-majong2", { repeat: Infinity, rotation: 360, ease: Linear.easeNone, duration: 25 });


            // If fast switch is enabled, skip to the end
            if (fastSwitch) {
                tl.seek('-=0', false);
                resolve();
                return;
            }
            resolve();
        })
    }
}