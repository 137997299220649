const ambientSrc = "/audio/ambient.mp3"; // path to ambient audio
const audios = [new Audio("/audio/click.mp3"), new Audio("/audio/click2.mp3"), new Audio("/audio/click3.mp3"), new Audio("/audio/click4.mp3")];
const audioToggleContainer = document.getElementById("sb-audio-toggle");
// Selector for all elements that need to have a "click" sound played on click
const clickSoundSelectors = ".sb-audio-clickable, .sb-nav-linked, .swiper-button-next, .swiper-button-prev";

let instance = null;
let clickedOnce = false;

export class AudioHandler {

    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    init() {
        this.clickEventListeners = [];

        // Load ambient audio
        this.ambientAudio = new Audio(ambientSrc);
        this.canPlayThroughAmbient = false;
        this.ambientAudio.loop = true;
        this.ambientAudio.addEventListener("canplaythrough", event => {
            /* the audio is now playable; play it if permissions allow */
            this.canPlayThroughAmbient = true;
            this.ambientAudio.play().then(function() {
                this.audioToggleContainer.classList.remove("sb-hidden");
                this.audioToggleContainer.classList.add("sb-active");
            }.bind(this));
        });

        // Playstate element
        this.audioToggleContainer = audioToggleContainer;
        this.audioToggleContainer.addEventListener("click", this.toggleAmbient.bind(this));

        // Start playing ambient audio on click if not yet playing
        window.addEventListener("click", function() {
            if (this.ambientAudio.paused && !clickedOnce) {
                clickedOnce = true;
                this.audioToggleContainer.classList.remove("sb-hidden");

                this.ambientAudio.play().then(function() {
                    this.audioToggleContainer.classList.add("sb-active");
                }.bind(this));
            }
        }.bind(this));

        const menuBtn = document.querySelector(".sb-menu-btn");
        menuBtn.addEventListener("click", playClickSound);

        this.linkClickSounds();
    }

    /**
     * Toggles the ambient music
     */
    toggleAmbient() {
        if (this.ambientAudio.paused) {
            this.ambientAudio.play();
            this.audioToggleContainer.classList.add("sb-active");
        } else {
            this.ambientAudio.pause();
            this.audioToggleContainer.classList.remove("sb-active");
        }
    }

    /**
     * Links elements to make "click" sound on click.
     */
    linkClickSounds() {
        console.log("Linking click sounds")
        if (Array.isArray(this.clickEventListeners)) {
            console.log("removing click sounds");
            // Remove listeners
            for (const el of this.clickEventListeners) {
                //el.removeEventListener("click", playClickSound);
            }
        }

        this.clickEventListeners = [];

        const elements = document.querySelectorAll(clickSoundSelectors);
        for (const el of elements) {
            el.addEventListener("click", playClickSound);

            this.clickEventListeners.push(el);
        }
    }

}
/**
 * Play the "click" sound
 */
function playClickSound() {
    let randomIndex = Math.round(Math.random() * (audios.length - 1));
    let audio = audios[randomIndex];

    if (audioToggleContainer.classList.contains("sb-active") || !clickedOnce) {
        audio.currentTime = 0;
        audio.play();
    }
}